<template>
  <div id="login-wrapper">
    <!-- Custom Background -->
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 50" preserveAspectRatio="none">
      <polygon fill="rgb(45, 48, 65)" points="0,42 100,24 100,100 0,100" />
    </svg>
    <!-- Login Component -->
    <div class="login-form">
      <!-- Heading Section -->
      <img class="move-svg" src="../../public/assets/MoveSVG.svg" alt="logo" :class="{ error: isError }" />
      <h4 class="deck-title" :class="{ error: isError }">Deck Platform</h4>
      <p class="init-desc" :class="{ error: isError }">{{ $t("message.logInCaption") }}</p>
      <!-- Input Section -->
      <div class="input-container" :class="{ error: isError }" @keyup.enter="loginH()">
        <div>
          <label id="user-label" class="text-label">
            <input v-model="username" class="login-input" type="text" placeholder="Username" />
          </label>
        </div>
        <div>
          <label id="password-label" class="text-label">
            <input v-model="password" class="login-input" type="password" placeholder="Password" />
          </label>
        </div>
      </div>
      <div class="checkbox-wrapper" :class="{ error: isError }">
        <label class="container" v-show="false">
          Ricorda Username
          <input v-model="remember" type="checkbox" checked="checked" />
          <span class="checkmark"></span>
        </label>
      </div>
      <p v-if="error" class="error-text" :class="{ error: isError }">{{ error }}</p>
      <button @click="loginH" class="submit-button" :class="{ error: isError }">{{ $t("message.logIn") }}</button>
      <!-- Footer Section -->
      <p id="bottom-help">
        {{ $t("message.helpMe") }}
        <a href="mailto:support@movesolutions.it">{{ $t("message.helpDesk") }}</a>
      </p>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import router from "../router";

export default {
  name: "login",
  data() {
    return {
      isError: false,
      error: "",
      username: "",
      password: "",
      remember: false,
    };
  },
  methods: {
    ...mapActions({ login: "auth/login" }),
    checkForm: function (e) {
      if (this.username && this.password) {
        this.resetError();
      }
      if (!this.username || !this.password) {
        this.displayError("Errore: credenziali richieste.");
      }
      e.preventDefault();
    },
    displayError: function (errorString) {
      this.error = errorString;
      this.isError = true;
      let self = this;
      setTimeout(function () {
        self.isError = false;
      }, 1000);
    },
    resetError: function () {
      this.error = "";
      this.isError = false;
    },

    loginH: async function () {
      /*       this.checkForm(); */
      try {
        let user = {
          username: this.username,
          password: this.password,
        };
        await this.login(user);
        if (this.isAdmin) {
          localStorage.setItem("eula", 'hide');
          router.push("admin");
        } else {
          localStorage.setItem("eula", 'show');
          router.push("dashboard");
        }
      } catch (error) {
        this.displayError("Credenziali Errate.");
      }
    },
  },
  computed: {
    ...mapGetters({ isAdmin: "auth/isAdmin" }),
  },
};
</script>
<style scoped>
/********** Main Component **********/

svg {
  overflow: unset;
}

#login-wrapper {
  text-align: center;
  font-style: normal;
  font-size: 1em;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  min-width: 1000px;
  width: 100%;
  position: fixed;
  background-color: rgb(21, 146, 230);
  height: 100%;
}

.login-form {
  display: flex;
  justify-content: center;
  margin: 0;
  position: fixed;
  top: 60%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 480px;
  height: 480px;
  background-color: rgb(247, 250, 252);
  border-radius: 8px;
}
.input-container {
  position: fixed;
  top: 30%;
}

/******* Text *******/

p {
  font-weight: 600;
  font-size: 0.5em;
  color: rgba(255, 255, 255, 1);
}

.error-text {
  color: red;
  position: fixed;
  font-size: 0.6em;
  top: 71%;
  left: 58%;
}

.deck-title {
  font-weight: bold;
  font-size: 1em;
  margin-top: 15px;
  margin-bottom: 0px;

  position: fixed;
  top: 14%;
}
.init-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 0.7em;
  color: rgba(45, 48, 65, 1);
  margin: 0;
  overflow: hidden;

  position: fixed;
  top: 24%;
}

#bottom-help {
  position: fixed;
  top: 101%;
  font-weight: 600;
  font-size: 0.55em;
  color: rgba(255, 255, 255, 1);
}
p > a {
  color: rgb(17, 205, 239);
}

/******* Img *******/

.move-svg {
  width: 168px;
  margin-top: 30px;

  position: fixed;
  left: 156px;
}

/******* Custom Text Input with SVG *******/

.text-label {
  position: relative;

  filter: drop-shadow(0px 2px 4px rgba(23, 23, 23, 0.18));
  -webkit-filter: drop-shadow(0px 2px 4px rgba(23, 23, 23, 0.18));
  -moz-filter: drop-shadow(0px 2px 4px rgba(23, 23, 23, 0.18));
  -o-filter: drop-shadow(0px 2px 4px rgba(23, 23, 23, 0.18));
  -ms-filter: drop-shadow(0px 2px 4px rgba(23, 23, 23, 0.18));
}

.text-label:before {
  z-index: 1;
  content: "";
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  width: 25px;
  background: url('../../public/assets/MailSVG.svg') center / contain no-repeat;
}

#password-label:before {
  background: url("../../public/assets/PasswordSVG.svg") center / contain no-repeat;
}

.login-input {
  font-size: 0.7em;
  font-weight: 400;
  padding: 15px 30px;
  width: 345px;
  margin-top: 38px;
  border-style: hidden;
  border-radius: 4px;
  text-indent: 2em;
  text-align: left;
  background-color: rgb(246, 246, 246);
}
.login-input:focus {
  outline: none;
  transition: box-shadow linear 0.3s;
  border-color: rgba(45, 48, 65, 1);
  box-shadow: 0 0 10px rgba(45, 48, 65, 0.2);
  -webkit-box-shadow: 0 0 10px rgba(45, 48, 65, 0.2);
  -ms-box-shadow: 0 0 10px rgba(45, 48, 65, 0.2);
}

/******* Custom Checkbox *******/

.check-wrapper {
  text-align: left;
  font-weight: normal;
  font-size: 0.55em;
}

.container {
  margin-top: 30px;
  margin-left: 40px;
  display: block;
  position: relative;
  padding-left: 45px;
  padding-top: 6px;
  margin-bottom: 12px;
  cursor: pointer;
  text-align: left;
  font-weight: normal;
  font-size: 0.6em;
  color: rgba(45, 48, 65, 1);
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

/* Hide the browser's default checkbox */
.container > input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: rgb(255, 255, 255);
  transition: background-color ease 0.1s;

  filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.129));
  -webkit-filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.129));
  -moz-filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.129));
  -o-filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.129));
  -ms-filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.129));
  border-style: hidden;
  border-radius: 6px;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: rgba(45, 48, 65, 1);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 12px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/******* Submit Button *******/

.submit-button {
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 110px;
  height: 50px;
  border: 0;
  border-radius: 4px;
  background-color: rgb(21, 146, 230);
  margin-top: 18px;
  font-weight: 700;
  font-size: 1em;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;

  position: fixed;
  top: 79%;
}

.submit-button:active,
.submit-button:focus,
.submit-button:focus:active {
  background-image: none;
  outline: 0;
  box-shadow: none;
}

.submit-button:hover {
  box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: box-shadow 0.2s;
}
.submit-button:focus:active {
  box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transform: scale(0.95);
  transition-property: transform;
  transition-duration: 0.02s;
  transition-timing-function: linear;
}

.checkbox-wrapper {
  position: fixed;
  top: 66%;
  left: 0%;
}

.error {
  /* also need animation and -moz-animation */
  animation: shake 0.5s linear;
  -moz-animation: shake 0.5s linear;
  -webkit-animation: shake 0.5s linear;
  -o-animation: shake 0.5s linear;
}
/* also need keyframes and -moz-keyframes */
@keyframes shake {
  8%,
  41% {
    transform: translateX(-8px);
  }
  25%,
  58% {
    transform: translateX(8px);
  }
  75% {
    transform: translateX(-5px);
  }
  92% {
    transform: translateX(5px);
  }
  0%,
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes shake {
  8%,
  41% {
    -moz-transform: translateX(-8px);
  }
  25%,
  58% {
    -moz-transform: translateX(8px);
  }
  75% {
    -moz-transform: translateX(-5px);
  }
  92% {
    -moz-transform: translateX(5px);
  }
  0%,
  100% {
    -moz-transform: translateX(0);
  }
}

@-webkit-keyframes shake {
  8%,
  41% {
    -webkit-transform: translateX(-8px);
  }
  25%,
  58% {
    -webkit-transform: translateX(8px);
  }
  75% {
    -webkit-transform: translateX(-5px);
  }
  92% {
    -webkit-transform: translateX(5px);
  }
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
}
</style>